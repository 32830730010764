import { Vue, Component } from "vue-property-decorator";
import { ApiSistemaPagos, ApiErrorResponse, AxiosHttpClient } from "apd.apiconnectors";
import { getUrl, ApiList } from "@/config/apiUrls";
import Spinner from "@/store/modules/spinnerModule";
import Snackbar from "@/store/modules/errorSnackbar";
import JsonExcel from "vue-json-excel";
import { Filtro, EstatusOperacion, OperacionExtendida } from "apd.models";
import { TableHeader } from "@/models/vuetifyModels";
import { mdiClose } from "@mdi/js";
import pagosForm from "@/components/forms/pagosForm.vue";
import pagosTable from "@/components/tables/pagosTable.vue";
import { ToDate } from "@/filters";
import { Rules } from "@/models/rules";

Vue.component("DownloadExcel", JsonExcel);

@Component({
  components: {
    pagosForm,
    pagosTable,
  },
})
export default class ReportePagosPersonalizadoComponent extends Vue {
  public IconClose: string = mdiClose;

  public dialog = false;
  public date = "";
  public date2 = "";
  public clienteSP = (): ApiSistemaPagos => {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    return new ApiSistemaPagos(client);
  };

  public headers: TableHeader[] = [
    {
      text: "Fecha",
      value: "fechaAplicacion",
      align: "center",
      sortable: true,
    },
    {
      text: "Folio PD",
      value: "folioAPD",
      align: "center",
      sortable: true,
    },
    {
      text: "Estado",
      value: "estado",
      align: "center",
      sortable: false,
    },
    {
      text: "Ciudad",
      value: "ciudad",
      align: "center",
      sortable: false,
    },
    {
      text: "Servicio",
      value: "servicio",
      align: "center",
      sortable: false,
    },
    {
      text: "Cajero",
      value: "cajero",
      align: "center",
      sortable: false,
    },
    {
      text: "Importe",
      value: "importe",
      align: "center",
      sortable: false,
    },
    {
      text: "Comision",
      value: "comision",
      align: "center",
      sortable: false,
    },
    {
      text: "IVA",
      value: "totalCuentaCheques",
      align: "center",
      sortable: false,
    },
    {
      text: "Total",
      value: "total",
      align: "center",
      sortable: false,
    },
    {
      text: "Forma de Pago",
      value: "formaPago",
      align: "center",
      sortable: false,
    },
    {
      text: "Referencia",
      value: "referenciaPago",
      align: "center",
      sortable: false,
    },
  ];
  public json_meta = [
    [
      {
        key: "charset",
        value: "utf-8",
      },
    ],
  ];
  public json_fields = {
    Fecha: {
      callback: (value: OperacionExtendida): string => {
        const res = this.fecha(value);
        return ToDate(res);
      },
    },
    FolioPD: "folioAPD",
    Estado: "estado",
    Ciudad: "municipio",
    Servicio: "nombreServicio",
    Cajero: "nombreKiosko",
    Importe: {
      field: "importeServicio",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    Comision: {
      field: "importeComision",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    IVA: {
      field: "impuestoComision",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    Total: {
      callback: (value: OperacionExtendida): string => {
        const res = value.importeServicio + value.importeComision + value.impuestoComision;
        return "$" + res.toFixed(2);
      },
    },
    FormaPago: "formaPago",
    Referencia: "referenciaPago",
  };

  public formData = [] as OperacionExtendida[];

  public get loading(): boolean {
    return Spinner.showLoader;
  }

  public get rules(): Rules {
    return this.$store.state.validationRules;
  }

  public fecha(item: OperacionExtendida): string | undefined {
    if (
      item.estatusOperacion == EstatusOperacion.Aplicada ||
      item.estatusOperacion == EstatusOperacion.AplicadaIncompleta
    ) {
      return item.fechaAplicacion;
    } else {
      return item.created;
    }
  }

  public async buscarReporte(formFiltro: Filtro): Promise<void> {
    Spinner.Show();

    if (formFiltro.idFormaPago == "001") {
      formFiltro.idFormaPago = undefined;
    }

    if (formFiltro.fechaInicial) {
      this.date = formFiltro.fechaInicial;
    }

    if (formFiltro.fechaFinal) {
      this.date2 = formFiltro.fechaFinal;
    }

    await this.clienteSP()
      .GetOperacionesFormaPago(formFiltro)
      .then((resp) => {
        this.formData = resp as unknown as OperacionExtendida[];
        this.dialog = true;
        Spinner.Hide();
      })
      .catch((resp) => {
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        this.dialog = false;
        Snackbar.Show(err.apiError.mensajeUsuario);
        Spinner.Hide();
      });
  }
}
