import { Vue, Component } from "vue-property-decorator";
import Spinner from "@/store/modules/spinnerModule";
import JsonExcel from "vue-json-excel";
import { EstatusOperacion, OperacionExtendida } from "apd.models";
import { mdiCalendarExport } from "@mdi/js";
import moment from "moment";
import { Rules } from "@/models/rules";

Vue.component("DownloadExcel", JsonExcel);

@Component({
  props: {
    date: String,
    date2: String,
    headers: {
      type: Array,
      required: true,
    },
    formData: {
      type: Array,
      required: true,
    },
    jsonFields: {
      type: Object,
      required: true,
    },
    nombreReporte: {
      type: String,
      default: "Reporte",
    },
    showDescripcion: {
      type: Boolean,
      default: false,
    },
    showCantidad: {
      type: Boolean,
      default: false,
    },
    showReferencia: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    toMoney(value: number): string {
      if (typeof value !== "number") {
        return value;
      }
      return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    toDate(value: string): string {
      if (value) {
        return moment(String(value)).format("DD/MM/YY");
      } else {
        return "sin registro";
      }
    },
  },
})
export default class PagosTable extends Vue {
  public IconExport: string = mdiCalendarExport;
  public tipoPago = "Pagos";
  public dialog = false;
  public dataToExport = [] as OperacionExtendida[];

  public get loading(): boolean {
    return Spinner.showLoader;
  }

  public get rules(): Rules {
    return this.$store.state.validationRules;
  }

  public get reporteNombre(): string {
    return this.$props.nombreReporte + this.$props.date + "/" + this.$props.date2 + ".xls";
  }

  public fp(item: string): string {
    if (item.includes("Tarjeta")) {
      return "Tarjeta";
    } else {
      return item;
    }
  }

  public fecha(item: OperacionExtendida): string | undefined {
    if (
      item.estatusOperacion == EstatusOperacion.Aplicada ||
      item.estatusOperacion == EstatusOperacion.AplicadaIncompleta
    ) {
      return item.fechaAplicacion;
    } else {
      return item.created;
    }
  }

  public totalPagos(): number {
    let res = 0;
    (this.$props.formData as OperacionExtendida[]).forEach(function () {
      ++res;
    });
    return res;
  }

  public totalImportes(): number {
    let res = 0;
    if (this.tipoPago == "Pagos") {
      (this.$props.formData as OperacionExtendida[]).forEach(function (e) {
        res = res + e.importeServicio;
      });
      return res;
    } else {
      (this.$props.formData as OperacionExtendida[]).forEach(function (e) {
        res = res + e.importeComision + e.impuestoComision;
      });
      return res;
    }
  }

  public TituloExcel(): string {
    let totales = "";
    totales = "Totales: | Pagos " + this.totalPagos();
    totales += "| | Importe $" + this.totalImportes();
    return totales;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public fetchExcelData() {
    return this.$props.formData.reverse();
  }
}
